<div>
  <button mat-fab
    [color]="'accent'"
    [disabled]="false"
    (click)="inputFile.click()"
    [type]="'button'">
    <mat-icon>add</mat-icon>
    <input #inputFile
      type="file"
      accept="image/x-png,image/jpeg"
      [name]="'attachment'"
      (change)="onSelectFile($event)"
      style="display: none;"
    />
  </button>
</div>
