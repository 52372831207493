import { Component, OnInit } from '@angular/core';
import { AreaEnum } from 'src/app/model/Enum/AreaEnum';
import { ImageUpload } from 'src/app/model/image-upload';
import { Publication } from 'src/app/model/publication';
import { PublicationService } from 'src/app/service/publication.service';

@Component({
  selector: 'app-adult',
  templateUrl: './adult.component.html',
  styleUrls: ['./adult.component.scss']
})
export class AdultComponent implements OnInit {

  images: Array<ImageUpload> = new Array<ImageUpload>();
  publications: Array<Publication> = new Array<Publication>();
  AREA_ADULTO = AreaEnum.Adulto;
  pathBanner = '../../../../../assets/banner/banner-adulto.jpg';
  pathBannerPhone = '../../../../../assets/banner/banner-adulto-phone.jpg';

  constructor(private publicationService: PublicationService) { }

  ngOnInit(): void {
    this.loadPublications();
  }

  loadPublications(): void {
    this.publicationService.GetByArea(AreaEnum.Adulto).subscribe(result => {
      this.publications = result;
    });
  }
}
