import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/@base/base.component';
import { Contact } from 'src/app/model/contact';
import { User } from 'src/app/model/user';
import { ContactService } from 'src/app/service/contact.service';
import { LoadingService } from 'src/app/service/loading.service';
import { UserService } from 'src/app/service/user.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { ChangePasswordValidation } from 'src/app/validation/change-password-validation';
import { ContactValidation } from 'src/app/validation/contact-validation';

@Component({
  selector: 'app-contacts-dialog',
  templateUrl: './contacts-dialog.component.html',
  styleUrls: ['./contacts-dialog.component.scss']
})
export class ContactsDialogComponent extends BaseComponent implements OnInit {

  contact: Contact = new Contact();
  showMessage = false;

  isLoading = false;

  constructor(public dialogRef: MatDialogRef<ContactsDialogComponent>,
              private loadingService: LoadingService,
              private contactService: ContactService,
              private alert: AlertService) {
                super();

                this.subscription = new Subscription();

                this.subscription = this.contactService.success$.subscribe(result => {
                  this.showMessage = true;
                });

                this.loadingService.getShowLoading().subscribe(result => {
                  this.isLoading = result;
                });
              }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  SaveContact(): void {
    const errorMessage = ContactValidation.ContactValidation(this.contact);
    if (!errorMessage) {
      this.contactService.Save(this.contact);
    } else {
      this.alert.warning('Atenção', errorMessage, { expanded: true });
    }
  }
}
