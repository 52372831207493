<div fxLayout="row" fxLayoutAlign="space-around center">
  <mat-form-field>
    <mat-label>Selecione o tópico da publicação</mat-label>
    <mat-select [(ngModel)]="publication.topicId">
      <mat-option *ngFor="let topic of topics" [value]="topic.id">{{ topic.description }}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-fab *ngIf="!publication.id"
    [color]="addTopicButton.color"
    [disabled]="addTopicButton.disabled"
    (click)="addTopicButton.Click()"
    [type]="addTopicButton.type">
    <mat-icon>{{ addTopicButton.icon }}</mat-icon>
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="space-around center">
  <mat-form-field>
    <mat-label>Selecione a área de publicação</mat-label>
    <mat-select [(ngModel)]="publication.area">
      <mat-option [value]="1">Adulto</mat-option>
      <mat-option [value]="2">Infantil</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div fxLayout="row">
  <mat-form-field>
    <mat-label>Texto de publicação</mat-label>
    <textarea matInput
      [(ngModel)]="publication.publicationText"
      placeholder="Informe aqui o texto para ser publicado"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdk
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="10">
    </textarea>
  </mat-form-field>
</div>
