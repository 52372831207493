<div fxLayout="column">
  <div fxLayout="row" class="publications" fxLayoutAlign="center center">
    <mat-card (click)="viewPublicaation(publication)" fxFlex="50" *ngFor="let publication of publications | slice: 0 : 1;" class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>
          {{ publication.topic.description }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="content" ngClass.lt-lg="card-content-phone" ngClass.gt-md="card-content-pc">
        {{ publication.publicationText }}
      </mat-card-content>
    </mat-card>
  </div>
  <div ngClass.lt-lg="actions-phone" ngClass.gt-md="actions-pc" fxFlex>
    <button fxFlex
      mat-raised-button
      ngClass.lt-lg="layout-button-phone"
      ngClass.gt-md="layout-button-pc"
      (click)="seeMorePublications()">Ver todas as publicações</button>
  </div>
</div>
