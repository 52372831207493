import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { ChangePasswordValidation } from 'src/app/validation/change-password-validation';
import { BaseComponent } from '../../@base/base.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {

  user: User = new User();

  oldPasswordType = 'password';
  newPasswordType = 'password';
  confirmPasswordType = 'password';

  iconOldPassword = 'visibility';
  iconNewPassword = 'visibility';
  iconConfirmPassword = 'visibility';

  visibleOldPassword = false;
  visibleNewPassword = false;
  visibleConfirmPassword = false;

  confirmPassword: string;

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,
              private userService: UserService,
              private alert: AlertService) {
                super();

                this.subscription = new Subscription();

                this.subscription = this.userService.success$.subscribe(result => {
                  this.dialogRef.close();
                  this.alert.success(result.message);
                });
              }

  ngOnInit(): void {
  }

  visibilityOldPassword(visible: boolean): void {
    if (visible) {
      this.oldPasswordType = 'text';
      this.iconOldPassword = 'visibility_off';
    } else {
      this.oldPasswordType = 'password';
      this.iconOldPassword = 'visibility';
    }
    this.visibleOldPassword = visible;
  }

  visibilityNewPassword(visible: boolean): void {
    if (visible) {
      this.newPasswordType = 'text';
      this.iconNewPassword = 'visibility_off';
    } else {
      this.newPasswordType = 'password';
      this.iconNewPassword = 'visibility';
    }
    this.visibleNewPassword = visible;
  }

  visibilityConfirmPassword(visible: boolean): void {
    if (visible) {
      this.confirmPasswordType = 'text';
      this.iconConfirmPassword = 'visibility_off';
    } else {
      this.confirmPasswordType = 'password';
      this.iconConfirmPassword = 'visibility';
    }
    this.visibleConfirmPassword = visible;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changePassword(): void {
    const errorMessage = ChangePasswordValidation.ChangePasswordValidation(this.user, this.confirmPassword);
    if (!errorMessage) {
      this.userService.ChangePassword(this.user);
    } else {
      this.alert.warning('Atenção', errorMessage, { expanded: true });
    }
  }

}
