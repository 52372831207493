<div fxLayout="row" fxLayoutAlign="space-around center">
  <mat-form-field>
    <mat-label>Pesquisar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Texto para ser pesquisado na coluna 'Publicação'" #input>
  </mat-form-field>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef ngClass.gt-md="column-area-pc"> Área </th>
      <td mat-cell *matCellDef="let element" ngClass.gt-md="column-area-pc"> {{ getArea(element) }} </td>
    </ng-container>

    <ng-container matColumnDef="topic">
      <th mat-header-cell *matHeaderCellDef ngClass.gt-md="column-topic-pc"> Tópico </th>
      <td mat-cell *matCellDef="let element" ngClass.gt-md="column-topic-pc"> {{element.topic.description}} </td>
    </ng-container>

    <ng-container matColumnDef="publication-text">
      <th mat-header-cell *matHeaderCellDef ngClass.gt-md="column-publication-text-pc"> Publicação </th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.publicationText" ngClass.gt-md="column-publication-text-pc"> {{element.publicationText}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Ações </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <button mat-icon-button *ngFor="let button of editDeleteButtons"
          [color]="button.color"
          [disabled]="button.disabled"
          (click)="button.Click(element)"
          [type]="button.type">
          <mat-icon>{{ button.icon }}</mat-icon>
        </button>
    </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
