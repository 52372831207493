import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AreaEnum } from 'src/app/model/Enum/AreaEnum';
import { ImageUpload } from 'src/app/model/image-upload';
import { UploadPhotosService } from 'src/app/service/upload-photos.service';
import { UploadPhotosPreviewComponent } from 'src/app/shared/upload-photos-preview/upload-photos-preview.component';

export class ImageArray {
  image: Blob;
}

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class SpaceComponent implements OnInit {

  title = 'Espaço';
  images: Array<ImageUpload> = new Array<ImageUpload>();

  showFlag = false;
  selectedImageIndex = -1;
  currentIndex = -1;

  imageObject: Array<ImageArray> = new Array<ImageArray>();


  constructor(private uploadPhotosService: UploadPhotosService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadSpacePhotos();
  }

  loadSpacePhotos(): void {
    this.uploadPhotosService.GetByArea(AreaEnum.Espaco).subscribe(result => {
      this.images = result;
      this.setObject();
    });
  }

  setObject(): void {
    if (this.images) {
      this.images.forEach((image, index) => {
        const obj = new ImageArray();
        obj.image = image.base64Image;

        this.imageObject.push(obj);
      });
    }
  }

  view(image: ImageUpload): void {
    const dialogRef = this.dialog.open(UploadPhotosPreviewComponent, {
      width: 'auto',
      height: 'auto',
      data: this.images
    });
  }

  showLightbox(index: number): void {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler(): void {
    this.showFlag = false;
    this.currentIndex = -1;
  }
}
