<div style="display:flex; flex-direction: row;">
  <div style="flex:38 1 0;">
      <mat-expansion-panel
          class="alert-expansible-area"
          [hideToggle]="!hasBody()"
          [ngClass]="!hasBody() ? 'disable-expansion' : ''"
          [expanded]="getOptions().expanded"
          [innerHtml]
          >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ title }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        {{ message }}

      </mat-expansion-panel>
  </div>

  <div style="flex:1 0 auto">
    <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
      <mat-icon class="close-toastr">close</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
