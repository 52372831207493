<div class="userName" fxLayout="row" fxLayoutAlign="center center">
  <button class="button-menu-toolbar" [matMenuTriggerFor]="menu"><mat-icon>settings</mat-icon></button>
  <mat-menu class="background-menu" #menu="matMenu">
    <button class="button-menu-toolbar" mat-menu-item (click)="changePassword()">
      <mat-icon>vpn_key</mat-icon>
      Alterar Senha
    </button>
    <button class="button-menu-toolbar" mat-menu-item (click)="logout()">
      <mat-icon>power_settings_new</mat-icon>
      Logout
    </button>
  </mat-menu>
</div>
