// import { getMultipleValuesInSingleSelectionError } from '@angular/cdk/collections';
// import { Component, ElementRef, OnInit, QueryList, ViewChild } from '@angular/core';
// import { Router } from '@angular/router';
// import { Menu } from './model/menu';
// import { MenuService } from './service/menu.service';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent implements OnInit {

//   @ViewChild('section') section!: ElementRef;
//   menus: Array<Menu> = new Array<Menu>();

//   constructor(private menuService: MenuService,
//               private router: Router) {
//   }

//   ngOnInit(): void {
//     this.GetMenus();
//   }

//   Home(): void {
//     this.router.navigateByUrl('');
//   }

//   GetMenus(): void {
//     this.menus =  this.menuService.GetMenus();
//   }

//   scrollToSection(index: number): void {
//     this.section.nativeElement.children[index].scrollIntoView({
//       behavior: 'smooth',
//       block: 'center',
//       inline: 'nearest'
//     });
//   }
// }

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseService } from './service/base/base.service';

@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
