import { PersonalData } from '../model/personal-data';

export class PersonalDataValidation {
  static PersonalDataValidation(personalData: PersonalData): string {
    let errorMessage = '';

    if (!personalData.title || personalData.title === 0) {
      errorMessage += '- Informe a titulação \n';
    }

    if (!personalData.name || personalData.name === '') {
      errorMessage += '- Informe seu nome completo \n';
    }

    if (!personalData.cpf || personalData.cpf === '') {
      errorMessage += '- Informe o CPF \n';
    }

    if (!personalData.crp || personalData.crp === '') {
      errorMessage += '- Informe o CRP \n';
    }

    if (!personalData.telephone || personalData.telephone === '') {
      errorMessage += '- Informe o telefone de contato \n';
    }

    if (!personalData.email || personalData.email === '') {
      errorMessage += '- Informe o email de contato \n';
    }

    if (!personalData.instagram || personalData.instagram === '') {
      errorMessage += '- Informe o instagram \n';
    }

    if (!personalData.facebook || personalData.facebook === '') {
      errorMessage += '- Informe o facebook \n';
    }

    if (!personalData.address.zipCode || personalData.address.zipCode === '') {
      errorMessage += '- Informe o CEP do escritório \n';
    }

    if (!personalData.address.neighborhood || personalData.address.neighborhood === '') {
      errorMessage += '- Informe o bairro do escritório \n';
    }

    if (!personalData.address.street || personalData.address.street === '') {
      errorMessage += '- Informe a rua do escritório \n';
    }

    if (!personalData.address.number || personalData.address.number === 0) {
      errorMessage += '- Informe o número do escritório \n';
    }

    if (!personalData.address.complement || personalData.address.complement === '') {
      errorMessage += '- Informe o complemento do escritório \n';
    }

    return errorMessage;
  }
}
