<mat-card-header>
  <mat-card-title>
    Upload de imagens
  </mat-card-title>
</mat-card-header>
<mat-card-content>
  <app-upload-photos-form [imagesUpload]="imagesUpload"></app-upload-photos-form>
</mat-card-content>
<mat-card-actions fxLayoutAlign="center center">
  <button mat-raised-button *ngFor="let button of saveCleanButtons"
    [color]="button.color"
    [disabled]="button.disabled"
    (click)="button.Click()"
    [type]="button.type">
    <mat-icon>{{ button.icon }}</mat-icon>
    {{ button.description }}
  </button>
</mat-card-actions>
<mat-divider [inset]="true"></mat-divider>
<mat-card-header>
  <mat-card-title>
    Imagens publicadas
  </mat-card-title>
</mat-card-header>
<mat-card-content>
  <app-upload-photos-tab-grid [editDeleteButtons]="editDeleteButtons"></app-upload-photos-tab-grid>
</mat-card-content>
