import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/@base/base.component';
import { Ibutton } from 'src/app/interface/ibutton';
import { Topic } from 'src/app/model/topic';
import { TopicService } from 'src/app/service/topic.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { TopicEditDialogComponent } from './topic-edit-dialog/topic-edit-dialog.component';
import { TopicGridComponent } from './topic-grid/topic-grid.component';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent extends BaseComponent implements OnInit {

  @ViewChild(TopicGridComponent) topicGrid: TopicGridComponent;

  topic: Topic = new Topic();

  closeButton: Ibutton;
  saveButton: Ibutton;

  inativeButton: Ibutton;
  editButton: Ibutton;

  constructor(private topicService: TopicService,
              private alert: AlertService,
              private dialogRef: MatDialogRef<TopicsComponent>,
              private dialog: MatDialog) {
    super();

    this.subscriptions = new Array<Subscription>();

    this.subscriptions.push(
      this.topicService.success$.subscribe(result => {
        this.topicGrid.loadTopics();
        this.topic = new Topic();
        this.alert.success(result.message);
      })
    );
  }

  ngOnInit(): void {
    this.getButtons();
  }

  getButtons(): void {
    this.saveButton = {
      description: 'Salvar',
      disabled: false,
      color: 'accent',
      type: 'button',
      icon: 'save',
      Click: () => {
        this.saveTopic();
      }
    };

    this.closeButton = {
      description: 'Fechar',
      disabled: false,
      color: 'basic',
      type: 'button',
      icon: 'close',
      Click: () => {
        this.closeTopic();
      }
    };

    this.inativeButton = {
      description: '',
      disabled: false,
      color: 'accent',
      type: '',
      icon: '',
      Click: (topic: Topic, active: boolean) => {
        this.inativeTopic(topic, active);
      }
    };

    this.editButton = {
      description: '',
      disabled: false,
      color: 'primary',
      type: 'button',
      icon: 'create',
      Click: (value: Topic) => {
        this.editTopic(value);
      }
    };
  }

  saveTopic(): void {
    if (this.topic && this.topic.description && this.topic.description !== '') {
      this.topicService.Save(this.topic);
    } else {
      this.alert.warning('Atenção', '- Informe o tópico', { expanded: true });
    }
  }

  editTopic(topic: Topic): void {
    const dialogRef = this.dialog.open(TopicEditDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: topic
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.topicService.Update(topic);
      }
    });
  }

  closeTopic(): void {
    this.dialogRef.close();
  }

  inativeTopic(topic: Topic, active: boolean): void {
    topic.active = active;
    this.topicService.Update(topic);
    this.topicGrid.loadTopics();
  }

}
