<div class="background" ngClass.lt-lg="background-phone" ngClass.gt-md="background-pc">
  <div class="container" fxFill fxLayoutAlign="center center">
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>
          {{ texto }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <iframe class="frame-location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.642556847167!2d-47.824855384920085!3d-21.206353884737563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b9bec9d12cccc9%3A0xfd6760bb7a60876!2sAv.%20Independ%C3%AAncia%2C%203840%20-%20sala%20140%20-%20Alto%20da%20Boa%20Vista%2C%20Ribeir%C3%A3o%20Preto%20-%20SP%2C%2014026-160!5e0!3m2!1spt-BR!2sbr!4v1617899340151!5m2!1spt-BR!2sbr"
          allowfullscreen=""
          loading="lazy">
        </iframe>
      </mat-card-content>
    </mat-card>
  </div>
</div>
