 <div class="example-card">
    <div mat-dialog-content>
      <div fxFlexFill class="example-container">
        <div fxLayout="column" fxLayoutGap="32px" [style.height]="'100%'">
          <img mat-card-image [src]="data.base64Image" alt="{{getNameImage()}}">
        </div>
      </div>
    </div>
  <div mat-dialog-actions fxLayoutAlign="center center">
    <button mat-raised-button color="'accent'" (click)="onNoClick()">Fechar</button>
  </div>
</div>
