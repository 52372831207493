import { MatPaginator } from '@angular/material/paginator';
import { Base } from './base/base';
import { AreaEnum } from './Enum/AreaEnum';
import { Topic } from './topic';

export class Publication extends Base {
  topicId: number;
  topic: Topic = new Topic();
  area: AreaEnum;
  publicationText: string;

  paginator: MatPaginator;
}
