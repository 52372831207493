import { Publication } from '../model/publication';

export class PublicationValidation {
  static PublicationValidation(publication: Publication): string {
    let errorMessage = '';

    if (!publication.topicId || publication.topicId === 0) {
      errorMessage += '- Informe o tópico de publicação \n';
    }

    if (!publication.area || publication.area === 0) {
      errorMessage += '- Informe a área de publicação \n';
    }

    if (!publication.publicationText || publication.publicationText === '') {
      errorMessage += '- Informe o texto para ser publicado \n';
    }

    return errorMessage;
  }
}
