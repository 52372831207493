import { Base } from './base/base';
import { AreaEnum } from './Enum/AreaEnum';

export class ImageUpload extends Base {
  area: AreaEnum;
  name: string;
  size: number;
  type: string;
  base64Image: Blob;
}
