import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/model/user';
import { LoadingService } from 'src/app/service/loading.service';
import { UserService } from 'src/app/service/user.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { LoginValidation } from 'src/app/validation/login-validation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: User;
  pass = 'password';
  icon = 'visibility';
  visible = false;

  userName: string;
  password: string;

  isLoading = false;

  constructor(
    private userService: UserService,
    private loadingService: LoadingService,
    private router: Router,
    private alert: AlertService
  ) {
    if (this.userService.CurrentTokenValue) {
      this.router.navigate(['/admin']);
    }

    this.loadingService.getShowLoading().subscribe(result => {
      this.isLoading = result;
    });
  }

  ngOnInit(): void {
    this.SetUserClass();
  }

  visibility(visible: boolean): void {
    if (visible) {
      this.pass = 'text';
      this.icon = 'visibility_off';
    } else {
      this.pass = 'password';
      this.icon = 'visibility';
    }
    this.visible = visible;
  }

  SetUserClass(): void {
    this.user = new User();
  }

  Login(): void {
    const errorMessage = LoginValidation.LoginValidation(this.user);
    if (!errorMessage) {
      this.userService.Login(this.user);
    } else {
      this.alert.warning('Atenção', errorMessage, { expanded: true });
    }
  }
}
