<div fxLayout="row" fxLayoutAlign="space-around center">
  <mat-form-field>
    <mat-label>Filtro da tabela</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Bebidas" #input>
  </mat-form-field>
</div>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Código </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Descrição </th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> Editar </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button
          [color]="editButton.color"
          [type]="editButton.type"
          [disabled]="editButton.disabled"
          (click)="editButton.Click(element)">
          <mat-icon>{{ editButton.icon }}</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef> Ativo </th>
      <td mat-cell *matCellDef="let element">
        <mat-slide-toggle #slide
          [color]="inativeButton.color"
          [checked]="element.active"
          [disabled]="inativeButton.disabled"
          (click)="inativeButton.Click(element, !slide.checked)">
        </mat-slide-toggle>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nenhum resgistro encontrado com o filtro informado</td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
