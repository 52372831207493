<mat-card-header>
  <mat-card-title>
    Dados Pessoais
  </mat-card-title>
</mat-card-header>
<mat-card-content>
  <app-personal-data-form [personalData]="personalData"></app-personal-data-form>
</mat-card-content>
<mat-divider [inset]="true"></mat-divider>
<mat-card-header>
  <mat-card-title>
    Endereço
  </mat-card-title>
</mat-card-header>
<mat-card-content>
  <app-personal-data-adress [address]="personalData.address"></app-personal-data-adress>
</mat-card-content>
<mat-card-actions>
  <button mat-raised-button
    [color]="saveButton.color"
    [type]="saveButton.type"
    [disabled]="saveButton.disabled"
    (click)="saveButton.Click()">
    <mat-icon>{{ saveButton.icon }} </mat-icon>
    {{ saveButton.description }}
  </button>
</mat-card-actions>
