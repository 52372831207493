<mat-card class="mat-elevation-z4">
  <mat-card-header>
    <mat-card-title>
      {{ title }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="image">
      <img *ngFor="let image of images; let i = index;" (click)="showLightbox(i)" mat-card-avatar src="{{ image.base64Image }}">
    </div>
  </mat-card-content>
</mat-card>

<ng-image-fullscreen-view
    [images]="imageObject"
    [imageIndex]="selectedImageIndex"
    [show]="showFlag"
    (close)="closeEventHandler()"></ng-image-fullscreen-view>
