import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Ibutton } from 'src/app/interface/ibutton';
import { UploadPhotosAdultComponent } from './upload-photos-adult/upload-photos-adult.component';
import { UploadPhotosKidsComponent } from './upload-photos-kids/upload-photos-kids.component';
import { UploadPhotosSpaceComponent } from './upload-photos-space/upload-photos-space.component';

@Component({
  selector: 'app-upload-photos-tab-grid',
  templateUrl: './upload-photos-tab-grid.component.html',
  styleUrls: ['./upload-photos-tab-grid.component.scss']
})
export class UploadPhotosTabGridComponent implements OnInit {

  @Input() editDeleteButtons: Array<Ibutton>;

  @ViewChild(UploadPhotosAdultComponent) uploadPhotosAdultComponent: UploadPhotosAdultComponent;
  @ViewChild(UploadPhotosKidsComponent) uploadPhotosKidsComponent: UploadPhotosKidsComponent;
  @ViewChild(UploadPhotosSpaceComponent) uploadPhotosSpaceComponent: UploadPhotosSpaceComponent;


  constructor() {
  }

  ngOnInit(): void {
  }
}
