<div fxLayout="row" fxLayoutAlign="space-around center">
  <mat-form-field>
    <mat-label>Selecione o tópico da publicação</mat-label>
      <mat-select [(ngModel)]="areaId" (selectionChange)="getArea($event.value)">
        <!-- <mat-option [value]="1">Adulto</mat-option>
        <mat-option [value]="2">Infantil</mat-option> -->
        <mat-option [value]="3">Espaço</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div fxLayout="row" fxLayoutAlign="space-around center">
  <mat-form-field class="example-chip-list">
    <mat-label>Imagens selecionadas</mat-label>
    <mat-chip-list #chipList aria-label="Seleção de Imagens">
      <mat-chip
        *ngFor="let image of imagesUpload"
        [selectable]="false"
        [removable]="true"
        (removed)="remove(image)">
        {{ image.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        [matChipInputFor]="chipList"
        [disabled]="true"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
    </mat-chip-list>
  </mat-form-field>
  <app-file
    #appFileRef
    [areaId]="areaId"
    [imagesUpload]="imagesUpload">
  </app-file>
</div>
