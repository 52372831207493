import { Contact } from '../model/contact';

export class ContactValidation {
  static ContactValidation(contact: Contact): string {
    let errorMessage = '';

    if (!contact.name || contact.name === '') {
      errorMessage += '- Informe seu nome \n';
    }

    if (!contact.telephone || contact.telephone === '') {
      errorMessage += '- Informe seu telefone \n';
    }

    return errorMessage;
  }
}
