import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { DefaultComponent } from './default.component';
import { MenuComponent } from './menu/menu.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarBottomComponent } from './toolbar-bottom/toolbar-bottom.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { LogoutComponent } from './logout/logout.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ClientModule } from '../client/client.module';
import { ContactsComponent } from './contacts/contacts.component';
import { ContactsDialogComponent } from './contacts/contacts-dialog/contacts-dialog.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    DefaultComponent,
    ToolbarComponent,
    MenuComponent,
    SidenavComponent,
    ToolbarBottomComponent,
    LogoutComponent,
    ChangePasswordComponent,
    ContactsComponent,
    ContactsDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    RouterModule,
    FlexLayoutModule,
    ClientModule,
    NgxMaskModule.forRoot(maskConfig),
  ]
})
export class DefaultModule { }
