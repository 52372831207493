<mat-tab-group [color]="'accent'">
  <!-- <mat-tab label="Adulto">
    <app-upload-photos-adult [editDeleteButtons]="editDeleteButtons"></app-upload-photos-adult>
  </mat-tab>
  <mat-tab label="Infantil">
    <app-upload-photos-kids [editDeleteButtons]="editDeleteButtons"></app-upload-photos-kids>
  </mat-tab> -->
  <mat-tab label="Espaço">
    <app-upload-photos-space [editDeleteButtons]="editDeleteButtons"></app-upload-photos-space>
  </mat-tab>
</mat-tab-group>
