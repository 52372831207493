<div fxLayout="row" fxLayoutAlign="space-around center">
  <mat-form-field class="input-login">
    <mat-label>{{ typeTopic }} tópico</mat-label>
    <input
    matInput
    [(ngModel)]="topic.description"
    name="description"
    [required]="true"
    [readonly]="false"
    [placeholder]="typeTopic + ' tópico'"
    [type]="'text'">
    <!-- <mat-error *ngIf="validation">{{validation.LoginMessage}}</mat-error> -->
  </mat-form-field>
</div>
