import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  birthdate = '08/05/1989';

  firstParagraph = 'Meu nome é Vanessa, tenho ' + this.getAge() + ' anos e sou psicóloga formada a 8 anos. Quando criança, até tentei ser bailarina, mas era estabanada demais para isso e, na verdade, nunca fui muito talentosa com esporte algum. Depois, até os 12 eu quis ser veterinária, mas quando descobri que precisaria fazer cirurgias e às vezes sacrificar alguns bichinhos, vi que isso não era pra mim. Sou apaixonada por gatos e cuidar de animais virou meu hobby.';
  secondParagraph = 'Um grande motivo pelo qual escolhi ser psicóloga foi por adorar ouvir e contar histórias, além de amar crianças e ter grande satisfação em contribuir nesse processo de auto conhecimento.';
  thirdParagraph = 'É por isso que sigo cada vez mais apaixonada pela minha profissão.';

  constructor() { }

  ngOnInit(): void {
  }

  getAge(): string {
    const timeDiff = Math.abs(Date.now() - new Date(this.birthdate).getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25).toString();
  }
}
