import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  success$ = new Subject<any>();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'is-loading': 'true' }),
    params: new HttpParams()
  };

  protected UrlService: string;
  protected env: any;

  constructor() {
    this.env = environment;
    this.UrlService = this.env.UrlServiceBase;
  }
}
