<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <div>
    Tem certeza que deseja apagar este registro?
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button (click)="onNoClick()">Não</button>
  <button mat-raised-button class="btn-yes" color="accent" [mat-dialog-close]="true" cdkFocusInitial>Sim</button>
</div>
