import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Ibutton } from 'src/app/interface/ibutton';
import { AreaEnum } from 'src/app/model/Enum/AreaEnum';
import { ImageUpload } from 'src/app/model/image-upload';
import { UploadPhotosService } from 'src/app/service/upload-photos.service';
import { UploadPhotosPreviewComponent } from 'src/app/shared/upload-photos-preview/upload-photos-preview.component';

@Component({
  selector: 'app-upload-photos-space',
  templateUrl: './upload-photos-space.component.html',
  styleUrls: ['./upload-photos-space.component.scss']
})
export class UploadPhotosSpaceComponent implements OnInit, AfterViewInit {

  @Input() editDeleteButtons: Array<Ibutton>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['image', 'name', 'size', 'actions'];
  dataSource: MatTableDataSource<ImageUpload> = new MatTableDataSource<ImageUpload>();

  imagesUpload: Array<ImageUpload> = new Array<ImageUpload>();

  constructor(private uploadPhotosService: UploadPhotosService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadImagesByArea();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  loadImagesByArea(): void {
    this.uploadPhotosService.GetByArea(AreaEnum.Espaco).subscribe(result => {
      this.imagesUpload = result;
      this.setDataSource();
    });
  }

  setDataSource(): void {
    setTimeout(() => {
      this.dataSource = new MatTableDataSource<ImageUpload>(this.imagesUpload);
      this.dataSource.paginator = this.paginator;
    });
  }

  view(image: ImageUpload): void {
    const dialogRef = this.dialog.open(UploadPhotosPreviewComponent, {
      width: 'auto',
      height: 'auto',
      data: image
    });
  }

}
