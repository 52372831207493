import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from 'src/app/model/menu';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() isSmall!: boolean;
  @Input() isAdministrative: string;

  menus: Array<Menu> = new Array<Menu>();

  constructor(private menuService: MenuService, private router: Router) {
  }

  ngOnInit(): void {
    this.GetMenus();
  }

  GetMenus(): void {
    if (this.isAdministrative === 'true') {
      this.menus =  this.menuService.GetAdminMenus();
    } else {
      this.menus =  this.menuService.GetPublicMenus();
    }
  }

  onClick(menu: Menu): void {
    if (!menu.childrens) {
      window.location.href = menu.path;
    }
  }
}
