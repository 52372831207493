<div [ngClass]="isLoading ? 'loading' : ''" fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutAlign="center center" style="position: absolute;">
  <mat-spinner [color]="'accent'" [fxHide]="isLoading === false"></mat-spinner>
</div>

<div fxLayout="row" fxLayout.fxFlex.gt-xs="column">
  <mat-drawer-container class="example-container" autosize>

    <mat-drawer #drawer [mode]="'over'" class="example-sidenav">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div *ngIf="isAdministrative === 'true'" class="logout">
          <app-logout></app-logout>
        </div>
        <app-menu [isSmall]="true" [isAdministrative]="isAdministrative"></app-menu>
      </div>
    </mat-drawer>

    <mat-drawer-content ngClass.lt-lg="background-phone" ngClass.gt-md="background-pc" fxLayout="column">
      <div fxLayout="column" fxFlexFill>
        <header>
          <app-toolbar [personalData]="personalData" [isAdministrative]="isAdministrative" [drawer]="drawer"></app-toolbar>
        </header>
        <main>
          <mat-card *ngIf="isAdministrative === 'true'">
            <router-outlet></router-outlet>
          </mat-card>
          <div *ngIf="isAdministrative === 'false'">
            <app-home-page *ngIf="isAdministrative === 'false'"></app-home-page>
            <router-outlet></router-outlet>
          </div>
        </main>
        <footer>
          <app-toolbar-bottom [personalData]="personalData" [ngClass]="isAdministrative === 'true' ? 'toolbar-bottom-administrative' : 'toolbar-bottom'" [isAdministrative]="isAdministrative"></app-toolbar-bottom>
        </footer>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
