import { Component, Input, OnInit } from '@angular/core';
import { PersonalData } from 'src/app/model/personal-data';
import { BaseService } from 'src/app/service/base/base.service';

@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.component.html',
  styleUrls: ['./personal-data-form.component.scss']
})
export class PersonalDataFormComponent implements OnInit {

  @Input() personalData: PersonalData;

  showId = false;

  constructor() {
  }

  ngOnInit(): void {
  }
}
