import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/utils/authenticate/auth-guard';
import { DefaultComponent } from '../default/default.component';

import { LoginComponent } from './login/login.component';
import { PersonalDataComponent } from './register/personal-data/personal-data.component';
import { PublicationComponent } from './register/publication/publication.component';
import { UploadPhotosComponent } from './register/upload-photos/upload-photos.component';
import { ContactsComponent } from './search/contacts/contacts.component';

const routes: Routes = [
  {
    path: 'admin',
    component: LoginComponent,
    data: {
      title: 'Login Administrativo'
    }
  },
  {
    path: 'admin/consulta',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    data: {
      isAdministrative: 'true'
    },
    children: [
      {
        path: 'contato',
        component: ContactsComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Visualização de contatos'
        }
      }
    ]
  },
  {
    path: 'admin/cadastro',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    data: {
      isAdministrative: 'true'
    },
    children: [
      {
        path: 'publicacao',
        component: PublicationComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Cadastro de Publicação'
        }
      },
      {
        path: 'dados-pessoais',
        component: PersonalDataComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Cadastro de Dados Pessoais'
        }
      },
      {
        path: 'imagens',
        component: UploadPhotosComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Cadastro de Imagens'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
