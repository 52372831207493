import { AfterViewInit, Component, DoCheck, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Ibutton } from 'src/app/interface/ibutton';
import { Topic } from 'src/app/model/topic';
import { TopicService } from 'src/app/service/topic.service';

@Component({
  selector: 'app-topic-grid',
  templateUrl: './topic-grid.component.html',
  styleUrls: ['./topic-grid.component.scss']
})
export class TopicGridComponent implements OnInit, AfterViewInit {

  @Input() inativeButton: Ibutton;
  @Input() editButton: Ibutton;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  topics: Array<Topic> = new Array<Topic>();

  displayedColumns: string[] = ['description', 'edit', 'active'];
  dataSource = new MatTableDataSource<Topic>();

  constructor(private topicService: TopicService) {
  }

  ngOnInit(): void {
    this.loadTopics();
    this.setDataSource();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  loadTopics(): void {
    this.topicService.GetAll().subscribe(result => {
      this.topics = result;
      this.setDataSource();
    });
  }

  setDataSource(): void {
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.topics);
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
