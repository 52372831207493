import { Component, Input, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { PersonalData } from 'src/app/model/personal-data';
import { PersonalDataService } from 'src/app/service/personal-data.service';
import { BaseComponent } from '../../@base/base.component';

@Component({
  selector: 'app-toolbar-bottom',
  templateUrl: './toolbar-bottom.component.html',
  styleUrls: ['./toolbar-bottom.component.scss']
})
export class ToolbarBottomComponent implements OnInit {

  @Input() drawer!: MatDrawer;
  @Input() isAdministrative: string;
  @Input() personalData: PersonalData;

  constructor() { }

  ngOnInit(): void { }

}
