import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { AboutComponent } from './about/about.component';
import { AdultComponent } from './adult/adult.component';
import { ClientComponent } from './client/client.component';
import { KidsComponent } from './kids/kids.component';
import { LocationComponent } from './location/location.component';
import { SpaceComponent } from './space/space.component';
import { ClientRoutingModule } from './client-routing.module';
import { AgmCoreModule } from '@agm/core';
import { HomePageComponent } from './home-page/home-page.component';
import { ShowPublicationDialogComponent } from './shared/show-publication-dialog/show-publication-dialog.component';
import { ShowPhotosComponent } from './shared/show-photos/show-photos.component';
import { ShowPublicationsComponent } from './shared/show-publications/show-publications.component';
import { SeeMorePublicationsComponent } from './shared/see-more-publications/see-more-publications.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';




@NgModule({
  declarations: [
    AboutComponent,
    AdultComponent,
    ClientComponent,
    KidsComponent,
    LocationComponent,
    SpaceComponent,
    HomePageComponent,
    ShowPublicationDialogComponent,
    ShowPublicationsComponent,
    ShowPhotosComponent,
    SeeMorePublicationsComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    RouterModule,
    FlexLayoutModule,
    ClientRoutingModule,
    NgImageFullscreenViewModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA17gyMVD3LOt4_kMeJMxrT-QdBFBULuMo'
    })
  ],
  exports: [
    HomePageComponent
  ]
})
export class ClientModule { }
