<div mat-dialog-title>Alteração de senha</div>
<div mat-dialog-content>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
    <mat-form-field>
      <mat-label>Senha Atual</mat-label>
      <input
        matInput
        name="password"
        [(ngModel)]="user.password"
        [required]="true"
        [readonly]="false"
        [placeholder]="'Senha'"
        [type]="oldPasswordType">
        <!-- <mat-error *ngIf="validation">{{validation.PasswordMessage}}</mat-error> -->
      <button class="padding-icon" type="button" mat-icon-button (click)="visibilityOldPassword(!visibleOldPassword)" matSuffix>
        <mat-icon class="icon">
          {{ iconOldPassword }}
        </mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
    <mat-form-field>
      <mat-label>Nova senha</mat-label>
      <input
        matInput
        name="newPassword"
        [(ngModel)]="user.newPassword"
        [required]="true"
        [readonly]="false"
        [placeholder]="'Nova senha'"
        [type]="newPasswordType">
        <!-- <mat-error *ngIf="validation">{{validation.PasswordMessage}}</mat-error> -->
      <button class="padding-icon" type="button" mat-icon-button (click)="visibilityNewPassword(!visibleNewPassword)" matSuffix>
        <mat-icon class="icon">
          {{ iconNewPassword }}
        </mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Confirmar senha</mat-label>
      <input
        matInput
        name="confirmPassword"
        [(ngModel)]="confirmPassword"
        [required]="true"
        [readonly]="false"
        [placeholder]="'Confirme sua senha'"
        [type]="confirmPasswordType">
        <!-- <mat-error *ngIf="getError()">As senhas estão diferentes</mat-error> -->
      <button class="padding-icon" type="button" mat-icon-button (click)="visibilityConfirmPassword(!visibleConfirmPassword)" matSuffix>
        <mat-icon class="icon">
          {{ iconConfirmPassword }}
        </mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button (click)="onNoClick()">Cancelar</button>
  <button mat-raised-button (click)="changePassword()" color="accent">Salvar</button>
</div>
