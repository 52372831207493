import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/@base/base.component';
import { Ibutton } from 'src/app/interface/ibutton';
import { PersonalData } from 'src/app/model/personal-data';
import { PersonalDataService } from 'src/app/service/personal-data.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { PersonalDataValidation } from 'src/app/validation/personal-data-validation';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent extends BaseComponent implements OnInit {

  personalData: PersonalData = new PersonalData();

  saveButton: Ibutton;

  constructor(private personalDataService: PersonalDataService,
              private alert: AlertService) {
    super();

    this.subscriptions = new Array<Subscription>();

    this.subscriptions.push(
      this.personalDataService.success$.subscribe(result => {
        this.alert.success(result.message);
        this.personalDataService.getPersonalData().subscribe(data => {
          console.log('personal-data-save');
        });
      })
    );

    this.subscriptions.push(
      this.personalDataService.personalData$.subscribe(result => {
        console.log('personal-data-change');
      })
    );
  }

  ngOnInit(): void {
    this.loadPersonalData();
    this.getButtons();
  }

  loadPersonalData(): void {
    this.personalDataService.Get().subscribe(result => {
      this.personalData = result;
      this.personalDataService.setPersonalData(this.personalData);
    });
  }

  getButtons(): void {
    this.saveButton = {
      description: 'Salvar',
      disabled: false,
      color: 'accent',
      type: 'button',
      icon: 'save',
      Click: () => {
        this.savePersonalData();
      }
    };
  }

  savePersonalData(): void {
    const errorMessage = PersonalDataValidation.PersonalDataValidation(this.personalData);
    if (!errorMessage) {
      if (!this.personalData.id || this.personalData.id === 0) {
        this.personalDataService.Save(this.personalData);
      } else {
        this.personalDataService.Update(this.personalData);
      }
    } else {
      this.alert.warning('Atenção', errorMessage, { expanded: true });
      this.loadPersonalData();
    }
  }

}
