import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Ibutton } from 'src/app/interface/ibutton';
import { Contact } from 'src/app/model/contact';
import { ContactService } from 'src/app/service/contact.service';

@Component({
  selector: 'app-contacts-grid',
  templateUrl: './contacts-grid.component.html',
  styleUrls: ['./contacts-grid.component.scss']
})
export class ContactsGridComponent implements OnInit, AfterViewInit {

  @Input() contactedButton: Ibutton;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  contacts: Array<Contact> = new Array<Contact>();

  displayedColumns: string[] = ['name', 'telephone', 'active'];
  dataSource = new MatTableDataSource<Contact>();

  constructor(private contactService: ContactService) {
  }

  ngOnInit(): void {
    this.loadContacts();
    this.setDataSource();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  loadContacts(): void {
    this.contactService.GetAll().subscribe(result => {
      this.contacts = result;
      this.setDataSource();
    });
  }

  setDataSource(): void {
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.contacts);
      this.dataSource.paginator = this.paginator;
    });
  }

}
