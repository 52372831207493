import { Component, OnInit } from '@angular/core';
import { AreaEnum } from 'src/app/model/Enum/AreaEnum';
import { ImageUpload } from 'src/app/model/image-upload';
import { Publication } from 'src/app/model/publication';
import { PublicationService } from 'src/app/service/publication.service';

@Component({
  selector: 'app-kids',
  templateUrl: './kids.component.html',
  styleUrls: ['./kids.component.scss']
})
export class KidsComponent implements OnInit {

  images: Array<ImageUpload> = new Array<ImageUpload>();
  publications: Array<Publication> = new Array<Publication>();
  AREA_INFANTIL = AreaEnum.Infantil;
  pathBanner = '../../../../../assets/banner/banner-kids.jpg';
  pathBannerPhone = '../../../../../assets/banner/banner-kids-phone.jpg';

  constructor(private publicationService: PublicationService) { }

  ngOnInit(): void {
    this.loadPublications();
  }

  loadPublications(): void {
    this.publicationService.GetByArea(AreaEnum.Infantil).subscribe(result => {
      this.publications = result;
    });
  }
}
