<mat-card-header>
  <mat-card-title>
    Nova publicação
  </mat-card-title>
</mat-card-header>
<mat-card-content>
  <app-publication-form [publication]="publication" [addTopicButton]="addTopicButton" [saveCleanButtons]="saveCleanButtons"></app-publication-form>
</mat-card-content>
<mat-card-actions fxLayoutAlign="center center">
  <button mat-raised-button *ngFor="let button of saveCleanButtons"
    [color]="button.color"
    [disabled]="button.disabled"
    (click)="button.Click()"
    [type]="button.type">
    <mat-icon>{{ button.icon }}</mat-icon>
    {{ button.description }}
  </button>
</mat-card-actions>
<mat-divider [inset]="true"></mat-divider>
<mat-card-header>
  <mat-card-title>
    Publicações publicadas
  </mat-card-title>
</mat-card-header>
<mat-card-content>
  <app-publication-grid [editDeleteButtons]="editDeleteButtons"></app-publication-grid>
</mat-card-content>
