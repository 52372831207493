import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { SpendingIndividualConfig } from '../model/individual-config';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private service: ToastrService) {
  }

  success(title: string, message?: string, config: Partial<SpendingIndividualConfig> = new SpendingIndividualConfig()) {
    this.service.success(message, title, config);
  }

  warning(title: string, message?: string, config: Partial<SpendingIndividualConfig> = new SpendingIndividualConfig()) {
    this.service.warning(message, title, config);
  }

  info(title: string, message?: string, config: Partial<SpendingIndividualConfig> = new SpendingIndividualConfig()) {
    this.service.info(message, title, config);
  }

  show(title: string, message?: string, config: Partial<SpendingIndividualConfig> = new SpendingIndividualConfig()) {
    this.service.show(message, title, config);
  }

  error(title: string, message?: string, config: Partial<SpendingIndividualConfig> = new SpendingIndividualConfig()) {
    this.service.error(message, title, config);
  }
}
