import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Contact } from '../model/contact';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  GetAll(): Observable<Array<Contact>> {
    return this.http.get<Array<Contact>>(`${environment.apiUrl}contact`);
  }

  Update(contact: Contact): Subscription {
    return this.http.put<string>(`${environment.apiUrl}contact`, contact, this.httpOptions).pipe(first()).subscribe(success => {
      this.success$.next(success);
    });
  }

  Save(contact: Contact): Subscription {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*' );
    this.httpOptions.headers.append('mode', 'no-cors' );
    return this.http.post<string>(`${environment.apiUrl}contact`, contact, this.httpOptions).pipe(first()).subscribe(success => {
      this.success$.next(success);
    });
  }
}
