import { Address } from './address';
import { Base } from './base/base';
import { TitleEnum } from './Enum/TitleEnum';

export class PersonalData extends Base {
  title: TitleEnum;
  name: string;
  cpf: string;
  crp: string;
  telephone: string;
  email: string;
  instagram: string;
  facebook: string;
  personalText: string;
  addressId: number;
  address: Address = new Address();
}
