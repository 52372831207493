import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastAlertComponent } from './toast-alert/toast-alert.component';
import { ToastrModule } from 'ngx-toastr';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    AngularMaterialModule,
    MatButtonModule
  ],
  declarations: [ToastAlertComponent],
  entryComponents: [ToastAlertComponent]
})
export class AlertModule { }
