import { AfterViewInit, Component, DoCheck, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Ibutton } from 'src/app/interface/ibutton';
import { AreaEnum } from 'src/app/model/Enum/AreaEnum';
import { Publication } from 'src/app/model/publication';
import { PublicationService } from 'src/app/service/publication.service';

@Component({
  selector: 'app-publication-grid',
  templateUrl: './publication-grid.component.html',
  styleUrls: ['./publication-grid.component.scss']
})
export class PublicationGridComponent implements OnInit, AfterViewInit {

  @Input() editDeleteButtons: Array<Ibutton>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  publications: Array<Publication> = new Array<Publication>();

  displayedColumns: string[] = ['area', 'topic', 'publication-text', 'actions'];
  dataSource = new MatTableDataSource<Publication>();

  constructor(private publicationService: PublicationService) {
  }

  ngOnInit(): void {
    this.loadAllPublications();
    this.setDataSource();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  loadAllPublications(): void {
    this.publicationService.GetAll().subscribe(result => {
      this.publications = result;
      this.setDataSource();
    });
  }

  getArea(element: Publication): string {
    if (element.area === AreaEnum.Adulto) {
      return 'Adulto';
    } else if (element.area === AreaEnum.Infantil) {
      return 'Infantil';
    } else {
      return 'INVÁLIDO';
    }
  }

  setDataSource(): void {
    setTimeout(() => {
      this.dataSource = new MatTableDataSource<any>(this.publications);
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
