import { IndividualConfig, ProgressAnimationType, ComponentType } from 'ngx-toastr';

export class SpendingIndividualConfig implements IndividualConfig {
  newestOnTop: boolean;
  disableTimeOut: boolean;  timeOut: number;
  closeButton = true;
  extendedTimeOut: number;
  progressBar: boolean;
  progressAnimation: ProgressAnimationType;
  enableHtml: boolean;
  toastClass: string;
  positionClass: string;
  titleClass: string;
  messageClass: string;
  easing: string;
  easeTime: string | number;
  tapToDismiss: boolean;
  toastComponent?: ComponentType<any>;
  onActivateTick: boolean;
  expanded: boolean;
}
