import { Injectable } from '@angular/core';
import { Menu } from '../model/menu';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseService {

  constructor() {
    super();
  }

  GetPublicMenus(): Array<Menu> {
    let menus = new Array<Menu>();
    menus = [
      {
        id: 1,
        description: 'Sobre',
        order: 2,
        path: '/sobre'
      },
      {
        id: 2,
        description: 'Localização',
        order: 5,
        path: '/localizacao',
      },
      {
        id: 3,
        description: 'Kids',
        order: 4,
        path: '/kids',
      },
      {
        id: 4,
        description: 'Adulto',
        order: 4,
        path: '/adulto',
      },
      {
        id: 5,
        description: 'Espaço',
        order: 6,
        path: '/espaco',
      },
      {
        id: 6,
        description: 'Inicio',
        order: 1,
        path: '/'
      },
    ];
    return menus.sort((a, b) => a.order - b.order);
  }

  GetAdminMenus(): Array<Menu> {
    let menus = new Array<Menu>();
    menus = [
      {
        id: 1,
        description: 'Publicação',
        order: 1,
        path: '/admin/cadastro/publicacao'
      },
      {
        id: 2,
        description: 'Dados Pessoais',
        order: 2,
        path: '/admin/cadastro/dados-pessoais',
      },
      {
        id: 3,
        description: 'Imagens',
        order: 3,
        path: '/admin/cadastro/imagens',
      },
      {
        id: 4,
        description: 'Contato',
        order: 3,
        path: '/admin/consulta/contato',
      }
    ];
    return menus.sort((a, b) => a.order - b.order);
  }
}
