import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/service/user.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private userService: UserService,
              private dialog: MatDialog) {
              }

  ngOnInit(): void {
  }

  logout(): void {
    this.userService.Logout();
  }

  changePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: 'auto',
      height: 'auto',
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.topicService.Update(topic);
    //   }
    // });
  }

}
