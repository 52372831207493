import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactsDialogComponent } from './contacts-dialog/contacts-dialog.component';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onClick(): void {
    const dialogRef = this.dialog.open(ContactsDialogComponent, {
      minHeight: 'auto',
      minWidth: '40vw'
    });
  }
}
