import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../angular-material.module';
import { AdminModule } from './admin/admin.module';
import { ClientModule } from './client/client.module';
import { DefaultModule } from './default/default.module';
import { DeleteDialogComponent } from './@base/delete-dialog/delete-dialog.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DeleteDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    RouterModule,
    FlexLayoutModule,
    ClientModule,
    AdminModule,
    DefaultModule,
  ],
})
export class ComponentsModule { }
