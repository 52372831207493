import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PersonalData } from 'src/app/model/personal-data';
import { LoadingService } from 'src/app/service/loading.service';
import { PersonalDataService } from 'src/app/service/personal-data.service';
import { BaseComponent } from '../../@base/base.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends BaseComponent implements OnInit {

  @Input() isAdministrative: string;

  personalData: PersonalData = new PersonalData();
  isLoading = false;

  constructor(private loadingService: LoadingService,
              private personalDataService: PersonalDataService) {

    super();

    this.loadingService.getShowLoading().subscribe(result => {
      setTimeout(() => {
        this.isLoading = result;
      });
    });

    this.subscriptions = new Array<Subscription>();

    this.subscriptions.push(
      this.personalDataService.getPersonalData().subscribe(result => {
        this.personalData = result;
      })
    );
  }

  ngOnInit(): void {
    this.loadPersonalData();
  }

  loadPersonalData(): void {
    this.personalDataService.getPersonalData().subscribe(data => {
      this.personalData = data;
    });

    if (!this.personalData.id) {
      this.personalDataService.Get().subscribe(result => {
        this.personalData = result;

        // if (!this.personalData.id) {
        //   this.personalDataService.Get().subscribe(data => {
        //     this.personalData = data;
        //   });
        // }
      });
    }
  }
}
