import { Component, Input, OnInit, Output } from '@angular/core';
import { ImageUpload } from 'src/app/model/image-upload';
import { AlertService } from '../alert/services/alert.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class AppFileComponent implements OnInit {

  @Input() imagesUpload: Array<ImageUpload>;
  @Input() areaId: number;

  constructor(private alert: AlertService) { }

  ngOnInit(): void {
  }

  onSelectFile(event: any): void {
    if (event.target.files && event.target.files[0] &&
      (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg')) {
      const image = new ImageUpload();
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      if (this.areaId && this.areaId !== 0) {
        image.area = this.areaId;
      }

      image.name = event.target.files[0].name;
      image.size = event.target.files[0].size;
      image.type = event.target.files[0].type;

      reader.onload = (event2: any) => { // called once readAsDataURL is completed
        image.base64Image = event2.target.result;

        this.imagesUpload.push(image);
      };
    } else {
      this.alert.warning('Atenção, selecione uma imagem válida');
    }
  }

  remove(image: any): void {
    const index: number = this.imagesUpload.indexOf(image);

    if (index !== - 1) {
      this.imagesUpload.splice(index, 1);
    }
  }
}
