import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageUpload } from 'src/app/model/image-upload';
import { UploadPhotosPreviewComponent } from 'src/app/shared/upload-photos-preview/upload-photos-preview.component';

@Component({
  selector: 'app-show-photos',
  templateUrl: './show-photos.component.html',
  styleUrls: ['./show-photos.component.scss']
})
export class ShowPhotosComponent implements OnInit {

  @Input() pathBanner: string;
  @Input() pathBannerPhone: string;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  view(image: ImageUpload): void {
    const dialogRef = this.dialog.open(UploadPhotosPreviewComponent, {
      width: 'auto',
      height: 'auto',
      data: image
    });
  }
}
