import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { LoginComponent } from './login/login.component';
import { AdminRoutingModule } from './admin-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PublicationComponent } from './register/publication/publication.component';
import { PersonalDataComponent } from './register/personal-data/personal-data.component';
import { UploadPhotosComponent } from './register/upload-photos/upload-photos.component';
import { TopicsComponent } from './register/topics/topics.component';
import { TopicFormComponent } from './register/topics/topic-form/topic-form.component';
import { TopicGridComponent } from './register/topics/topic-grid/topic-grid.component';
import { TopicEditDialogComponent } from './register/topics/topic-edit-dialog/topic-edit-dialog.component';
import { PublicationFormComponent } from './register/publication/publication-form/publication-form.component';
import { PublicationGridComponent } from './register/publication/publication-grid/publication-grid.component';
import { PublicationEditDialogComponent } from './register/publication/publication-edit-dialog/publication-edit-dialog.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from 'src/app/functions/CustomPaginator';
import { PersonalDataFormComponent } from './register/personal-data/personal-data-form/personal-data-form.component';
import { PersonalDataAdressComponent } from './register/personal-data/personal-data-adress/personal-data-adress.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { UploadPhotosFormComponent } from './register/upload-photos/upload-photos-form/upload-photos-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadPhotosTabGridComponent } from './register/upload-photos/upload-photos-tab-grid/upload-photos-tab-grid.component';
import { UploadPhotosAdultComponent } from './register/upload-photos/upload-photos-tab-grid/upload-photos-adult/upload-photos-adult.component';
import { UploadPhotosKidsComponent } from './register/upload-photos/upload-photos-tab-grid/upload-photos-kids/upload-photos-kids.component';
import { UploadPhotosSpaceComponent } from './register/upload-photos/upload-photos-tab-grid/upload-photos-space/upload-photos-space.component';
import { ContactsComponent } from './search/contacts/contacts.component';
import { ContactsGridComponent } from './search/contacts/contacts-grid/contacts-grid.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    LoginComponent,
    PublicationComponent,
    PersonalDataComponent,
    UploadPhotosComponent,
    TopicsComponent,
    TopicFormComponent,
    TopicGridComponent,
    TopicEditDialogComponent,
    PublicationFormComponent,
    PublicationGridComponent,
    PublicationEditDialogComponent,
    PersonalDataFormComponent,
    PersonalDataAdressComponent,
    UploadPhotosFormComponent,
    UploadPhotosTabGridComponent,
    UploadPhotosAdultComponent,
    UploadPhotosKidsComponent,
    UploadPhotosSpaceComponent,
    ContactsComponent,
    ContactsGridComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    AngularMaterialModule,
    RouterModule,
    FlexLayoutModule,
    AdminRoutingModule,
    FormsModule,
    SharedModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
  ]
})
export class AdminModule { }
