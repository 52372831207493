import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleEnum } from 'src/app/model/Enum/TitleEnum';
import { PersonalData } from 'src/app/model/personal-data';
import { PersonalDataService } from 'src/app/service/personal-data.service';
import { BaseComponent } from '../../@base/base.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() drawer!: MatDrawer;
  @Input() isAdministrative: string;
  @Input() personalData: PersonalData;

  constructor(private router: Router) { }

  ngOnInit(): void { }

  // goToComponentB(): void {
  //   this.router.navigate(['/sobre'], {state: {data: {...this.personalData}}});
  // }

  getTitulacao(): string {
    if (this.personalData) {
      if (this.personalData.title === TitleEnum.Bacharel) {
        return '';
      }

      if (this.personalData.title === TitleEnum.Mestra) {
        return 'Mª.';
      }

      if (this.personalData.title === TitleEnum.Doutora) {
        return 'Dra.';
      }
    }

    return '';
  }

  Home(): void {
    window.location.href = '/';
  }

}
