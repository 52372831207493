import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/@base/base.component';
import { DeleteDialogComponent } from 'src/app/components/@base/delete-dialog/delete-dialog.component';
import { Ibutton } from 'src/app/interface/ibutton';
import { AreaEnum } from 'src/app/model/Enum/AreaEnum';
import { ImageUpload } from 'src/app/model/image-upload';
import { UploadPhotosService } from 'src/app/service/upload-photos.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { UploadPhotosValidation } from 'src/app/validation/upload-photos-validation';
import { UploadPhotosFormComponent } from './upload-photos-form/upload-photos-form.component';
import { UploadPhotosTabGridComponent } from './upload-photos-tab-grid/upload-photos-tab-grid.component';

@Component({
  selector: 'app-upload-photos',
  templateUrl: './upload-photos.component.html',
  styleUrls: ['./upload-photos.component.scss']
})
export class UploadPhotosComponent extends BaseComponent implements OnInit {

  imagesUpload: Array<ImageUpload> = new Array<ImageUpload>();

  saveCleanButtons: Array<Ibutton> = new Array<Ibutton>();
  saveButton: Ibutton;
  cleanButton: Ibutton;

  editDeleteButtons: Array<Ibutton> = new Array<Ibutton>();
  deleteButton: Ibutton;

  @ViewChild(UploadPhotosFormComponent) uploadPhotosForm: UploadPhotosFormComponent;
  @ViewChild(UploadPhotosTabGridComponent) uploadPhotosTabGridComponent: UploadPhotosTabGridComponent;

  constructor(private uploadPhotosService: UploadPhotosService,
              private alert: AlertService,
              private dialog: MatDialog) {
    super();

    this.subscriptions = new Array<Subscription>();

    this.subscriptions.push(
      this.uploadPhotosService.success$.subscribe(result => {
        this.loadPhotos();
        this.cleanImage();
        this.alert.success(result.message);
      })
    );
  }

  ngOnInit(): void {
    this.getButtons();
  }

  loadPhotos(): void {
    if (this.imagesUpload[0].area === AreaEnum.Adulto) {
      this.uploadPhotosTabGridComponent.uploadPhotosAdultComponent.loadImagesByArea();
    } else if (this.imagesUpload[0].area === AreaEnum.Infantil) {
      this.uploadPhotosTabGridComponent.uploadPhotosKidsComponent.loadImagesByArea();
    } else {
      this.uploadPhotosTabGridComponent.uploadPhotosSpaceComponent.loadImagesByArea();
    }
  }

  getButtons(): void {
    this.saveButton = {
      description: 'Salvar',
      disabled: false,
      color: 'accent',
      type: 'button',
      icon: 'save',
      Click: () => {
        this.saveImage();
      }
    };

    this.cleanButton = {
      description: 'Limpar',
      disabled: false,
      color: 'basic',
      type: 'button',
      icon: 'cleaning_services',
      Click: () => {
        this.cleanImage();
      }
    };

    this.saveCleanButtons.push(this.saveButton);
    this.saveCleanButtons.push(this.cleanButton);

    this.deleteButton = {
      description: '',
      disabled: false,
      color: 'warn',
      type: 'button',
      icon: 'delete',
      Click: (image: ImageUpload) => {
        this.deleteImage(image);
      }
    };

    this.editDeleteButtons.push(this.deleteButton);
  }

  saveImage(): void {
    const errorMessage = UploadPhotosValidation.UploadPhotosValidation(this.imagesUpload[0]);
    if (!errorMessage) {
      this.uploadPhotosService.SaveList(this.imagesUpload);
    } else {
      this.alert.warning('Atenção', errorMessage, { expanded: true });
      this.loadPhotos();
    }
  }

  cleanImage(): void {
    this.imagesUpload = new Array<ImageUpload>();
    this.uploadPhotosForm.areaId = 0;
  }

  deleteImage(image: ImageUpload): void {
    if (image) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: '50%',
        height: 'auto',
        data: {
          title: 'Excluir publicação',
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.imagesUpload[0] = image;
          this.uploadPhotosService.Delete(image);
        }
      });
    }
  }
}
