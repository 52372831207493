import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Topic } from 'src/app/model/topic';
import { BaseService } from 'src/app/service/base/base.service';

@Component({
  selector: 'app-topic-edit-dialog',
  templateUrl: './topic-edit-dialog.component.html',
  styleUrls: ['./topic-edit-dialog.component.scss']
})
export class TopicEditDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TopicEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Topic) {
              }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  Update(): void {
    this.dialogRef.close(true);
  }
}
