import { User } from "../model/user";

export class ChangePasswordValidation {
  static ChangePasswordValidation(user: User, confirmPassword: string): string {
    let errorMessage = '';

    if (!user.password || user.password === '') {
      errorMessage += '- Informe a senha atual \n';
    }

    if (!user.newPassword || user.newPassword === '') {
      errorMessage += '- Informe a nova senha \n';
    }

    if (!confirmPassword || confirmPassword === '') {
      errorMessage += '- Confirme a nova senha \n';
    }

    if (user.newPassword && confirmPassword) {
      if (user.newPassword !== confirmPassword) {
        errorMessage += '- As senhas não conferem \n';
      }
    }

    return errorMessage;
  }
}
