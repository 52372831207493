import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  template: ''
})
export abstract class BaseComponent implements OnDestroy {

  subscription: Subscription = new Subscription();
  subscriptions: Subscription[];

  constructor() {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(x => x.unsubscribe());
    }
  }
}
