<div mat-dialog-title>
  <h2>Tópicos</h2>
</div>
<div mat-dialog-content>
  <app-topic-form [typeTopic]="'Novo'" [topic]="topic"></app-topic-form>
  <div fxLayoutAlign="center center">
    <button mat-raised-button
      [color]="saveButton.color"
      [disabled]="saveButton.disabled"
      [type]="saveButton.type"
      (click)="saveButton.Click()">
      <mat-icon>{{ saveButton.icon }}</mat-icon>
      {{ saveButton.description }}
    </button>
  </div>
  <mat-divider></mat-divider>
  <mat-card fxLayout="row" fxLayoutAlign="space-around center">
    <app-topic-grid
      [inativeButton]="inativeButton"
      [editButton]="editButton">
    </app-topic-grid>
  </mat-card>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button
    [color]="closeButton.color"
    [disabled]="closeButton.disabled"
    [type]="closeButton.type"
    (click)="closeButton.Click()">
    <mat-icon>{{ closeButton.icon }}</mat-icon>
    {{ closeButton.description }}
  </button>
</div>
