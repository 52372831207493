<h1 mat-dialog-title>Editar</h1>
<div mat-dialog-content>
  <div>
    <app-topic-form [typeTopic]="'Editar'" [topic]="data"></app-topic-form>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button (click)="onNoClick()">Cancelar</button>
  <button mat-raised-button (click)="Update()" color="accent">Salvar</button>
</div>
