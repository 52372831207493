<div [ngClass]="isLoading ? 'loading' : ''" fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutAlign="center center" style="position: absolute;">
  <mat-spinner [color]="'accent'" [fxHide]="isLoading === false"></mat-spinner>
</div>

<div mat-dialog-title>Deixe seu contato</div>
<div mat-dialog-content *ngIf="!showMessage">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input
        matInput
        name="name"
        [(ngModel)]="contact.name"
        [required]="true"
        [readonly]="false"
        [placeholder]="'Nome'"
      >
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
    <mat-form-field>
      <mat-label>Telefone</mat-label>
      <input
        #tel
        matInput
        name="Telephone"
        [(ngModel)]="contact.telephone"
        [required]="true"
        [readonly]="false"
        [placeholder]="'Telefone'"
        [mask]="'(00) 00000-0000'"
      >
    </mat-form-field>
  </div>
</div>
<div mat-dialog-content *ngIf="showMessage">
  <p>Obrigada pelo contato. <br/>Recebi sua mensagem, logo entrarei em contato</p>
</div>
<div *ngIf="!showMessage" mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button (click)="onNoClick()">Cancelar</button>
  <button mat-raised-button (click)="SaveContact()" color="accent">Salvar</button>
</div>
<div *ngIf="showMessage" mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button color="accent" (click)="onNoClick()">Fechar</button>
</div>
