import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from 'src/app/service/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: UserService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const currentUser = this.authenticationService.CurrentUserValue;
      const currentToken = this.authenticationService.CurrentTokenValue;

      if (currentUser && currentToken && currentUser.accessToken) {
          return true;
      }

      this.router.navigate(['/admin'], { queryParams: { returnUrl: state.url }});
      return false;
    }
}
