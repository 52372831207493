import { Component, Input, OnInit } from '@angular/core';
import { Topic } from 'src/app/model/topic';
import { BaseService } from 'src/app/service/base/base.service';

@Component({
  selector: 'app-topic-form',
  templateUrl: './topic-form.component.html',
  styleUrls: ['./topic-form.component.scss']
})
export class TopicFormComponent implements OnInit {

  @Input() topic: Topic;
  @Input() typeTopic: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
