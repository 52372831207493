import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/@base/base.component';
import { Ibutton } from 'src/app/interface/ibutton';
import { Contact } from 'src/app/model/contact';
import { ContactService } from 'src/app/service/contact.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { ContactsGridComponent } from './contacts-grid/contacts-grid.component';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent extends BaseComponent implements OnInit {

  contactedButton: Ibutton;

  @ViewChild(ContactsGridComponent) contactGrid: ContactsGridComponent;

  constructor(private contactService: ContactService,
              private alert: AlertService) {
    super();

    this.subscriptions = new Array<Subscription>();

    this.subscriptions.push(
      this.contactService.success$.subscribe(result => {
        this.contactGrid.loadContacts();
        this.alert.success(result.message);
      })
    );
  }

  ngOnInit(): void {
    this.getButtons();
  }

  getButtons(): void {
    this.contactedButton = {
      description: '',
      disabled: false,
      color: 'accent',
      type: '',
      icon: '',
      Click: (contact: Contact, contacted: boolean) => {
        this.contactContacted(contact, contacted);
      }
    };
  }

  contactContacted(contact: Contact, contacted: boolean): void {
    contact.contacted = contacted;
    this.contactService.Update(contact);
    this.contactGrid.loadContacts();
  }
}
