import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Publication } from 'src/app/model/publication';

@Component({
  selector: 'app-show-publication-dialog',
  templateUrl: './show-publication-dialog.component.html',
  styleUrls: ['./show-publication-dialog.component.scss']
})
export class ShowPublicationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ShowPublicationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Publication) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
