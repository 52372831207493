<div class="background home-page" ngClass.lt-lg="background-phone" ngClass.gt-md="background-pc" [fxShow]="true" [fxHide.gt-md]="true">
  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <div class="text-coursive-phone">
      Olá, sejam bem-vindos ao meu site!
    </div>
    <div ngClass.lt-lg="image-phone">
      <img ngClass.lt-lg="image-phone-size" src="../../../../assets/foto-home-about.png">
    </div>
  </div>
  <br />
  <div class="text-phone" fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="text-body">
      <p class="recuo-phone">
        {{ firstParagraph }}
      </p>
      <p class="recuo-phone">
        {{ secondParagraph }}
      </p>
      <p class="recuo-phone">
        {{ thirdParagraph }}
      </p>
      <br />
      <p class="text-coursive-phone">
        Aproveitem o espaço!
      </p>
    </div>
  </div>
</div>

<div class="background home-page" ngClass.lt-lg="background-phone" ngClass.gt-md="background-pc" [fxShow]="true" [fxHide.lt-lg]="true">
  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <div fxLayout="column" fxLayoutAlign="center center" class="text-side" fxFlex="60">
      <div>
        <p class="text-coursive">
          Olá, <br />sejam bem-vindos ao meu site!
        </p>
        <br />
        <div class="text">
          <p class="recuo">
            {{ firstParagraph }}
          </p>
          <p class="recuo">
            {{ secondParagraph }}
          </p>
          <p class="recuo">
            {{ thirdParagraph }}
          </p>
        </div>
        <br />
        <p class="text-coursive text-coursive-footer">
          Aproveitem o espaço!
        </p>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="image-side">
      <div class="image" fxLayoutAlign="center center">
        <img class="image-size" src="../../../../assets/foto-home-about.png">
      </div>
      <br />
      <div class="psych-text" fxLayoutAlign="center center">
        <p>Psicologia</p>
      </div>
      <br />
    </div>
  </div>
</div>
