<div class="background" ngClass.lt-lg="background-phone" ngClass.gt-md="background-pc" [fxShow]="true" [fxHide.gt-md]="true">
  <div fxLayout="column" fxLayoutAlign="space-evenly center">
    <div ngClass.lt-lg="image-phone">
      <img ngClass.lt-lg="image-phone-size" src="../../../../assets/foto-home-about.png">
    </div>
    <div class="text-coursive-phone">
      Formação
    </div>
  </div>
  <br />
  <div class="text-phone" fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="text-body">
      <p class="recuo-phone" *ngFor="let text of texts" [innerHtml]="text"></p>
    </div>
  </div>
</div>

<div class="background" ngClass.lt-lg="background-phone" ngClass.gt-md="background-pc" [fxShow]="true" [fxHide.lt-lg]="true">
  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <div fxLayout="column" fxLayoutAlign="center center" class="text-side" fxFlex="60">
      <div class="text-coursive">
        Formação
      </div>
      <div class="text">
        <p class="recuo" *ngFor="let text of texts" [innerHtml]="text"></p>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="image-side">
      <div class="image" fxLayoutAlign="center center">
        <img class="image-size" src="../../../../assets/foto-home-about.png">
      </div>
    </div>
  </div>
</div>
