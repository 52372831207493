import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonalDataService } from 'src/app/service/personal-data.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  texts: string[] = [];
  isHomePage = false;

  constructor(private personalDataService: PersonalDataService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.loadPersonalData();
    this.route.data.subscribe(v => this.isHomePage = v.isHomePage);
  }

  loadPersonalData(): void {
    this.personalDataService.Get().subscribe(result => {
      this.texts = this.getText(result.personalText);
    });
  }

  getText(text: string): string[] {
    const myArray = text.split('\n');

    myArray.forEach(myText => {
      const index = myArray.findIndex(item => item === '');
      myArray[index] = '<br/>';
    });

    return myArray;
  }
}
