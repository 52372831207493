<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
  <mat-form-field>
    <mat-label>CEP</mat-label>
    <input matInput
    [(ngModel)]="address.zipCode"
    [mask]="'00000-000'"
    placeholder="Informe aqui seu CEP" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Bairro</mat-label>
    <input matInput
    [(ngModel)]="address.neighborhood"
    placeholder="Informe aqui o bairro" />
  </mat-form-field>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
  <mat-form-field fxFlex.gt-md="60">
    <mat-label>Rua</mat-label>
    <input matInput
    [(ngModel)]="address.street"
    placeholder="Informe aqui a rua" />
  </mat-form-field>
  <mat-form-field fxFlex.gt-md="20">
    <mat-label>Número</mat-label>
    <input matInput
    [(ngModel)]="address.number"
    placeholder="Informe aqui o número" />
  </mat-form-field>
  <mat-form-field fxFlex.gt-md="20">
    <mat-label>Complemento</mat-label>
    <input matInput
    [(ngModel)]="address.complement"
    placeholder="Informe aqui o complemento" />
  </mat-form-field>
</div>
