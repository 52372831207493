import { Component, Input, OnInit } from '@angular/core';
import { Ibutton } from 'src/app/interface/ibutton';
import { AreaEnum } from 'src/app/model/Enum/AreaEnum';
import { Publication } from 'src/app/model/publication';
import { Topic } from 'src/app/model/topic';
import { TopicService } from 'src/app/service/topic.service';

@Component({
  selector: 'app-publication-form',
  templateUrl: './publication-form.component.html',
  styleUrls: ['./publication-form.component.scss']
})
export class PublicationFormComponent implements OnInit {

  @Input() publication: Publication;
  @Input() addTopicButton: Ibutton;
  @Input() saveCleanButtons: Array<Ibutton>;

  topics: Array<Topic> = new Array<Topic>();

  constructor(private topicService: TopicService) {
  }

  ngOnInit(): void {
    this.loadActiveTopics();
  }

  loadActiveTopics(): void {
    this.topicService.GetAllActives().subscribe(result => {
      this.topics = result;
    });
  }
}
