import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material.module';
import { AlertModule } from './alert/alert.module';
import { AppFileComponent } from './file/file.component';
import { FileUploadModule } from 'ng2-file-upload';
import { AppFileSelectDirective } from './file/directive/file-selected.directive';
import { AppFileDropDirective } from './file/directive/file-drop.directive';
import { UploadPhotosPreviewComponent } from './upload-photos-preview/upload-photos-preview.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AdminRoutingModule } from '../components/admin/admin-routing.module';
import { FormsModule } from '@angular/forms';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';

@NgModule({
  declarations: [
  AppFileSelectDirective,
  AppFileDropDirective,
  AppFileComponent,
  UploadPhotosPreviewComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    AlertModule,
    FileUploadModule,
    HttpClientModule,
    RouterModule,
    FlexLayoutModule,
    AdminRoutingModule,
    NgImageFullscreenViewModule,
    FormsModule
  ],
  exports: [
    AppFileSelectDirective,
    AppFileDropDirective,
    AppFileComponent,
  ]
})
export class SharedModule { }
