export enum ErrorStatusEnum {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TimeOut = 408,
  InternalServerError = 500,
  NotImplemented = 501,
  ServiceUnavailable = 503
}
