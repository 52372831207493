import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';
import { AlertService } from '../shared/alert/services/alert.service';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private currentTokenSubject: BehaviorSubject<string>;
  public currentToken: Observable<string>;

  constructor(private http: HttpClient, private router: Router, private alert: AlertService) {
    super();

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentTokenSubject = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('currentToken') || '{}'));
    this.currentToken = this.currentTokenSubject.asObservable();
  }

  public get CurrentUserValue(): User {
      return this.currentUserSubject.value;
  }

  public get CurrentTokenValue(): string {
    return this.currentTokenSubject.value;
  }

  Login(user: User): Subscription {
    const formData: any = new FormData();
    formData.append('login', user.login);
    formData.append('password', user.password);

    return this.http.post<User>(`${environment.apiUrl}user/auth`, user, this.httpOptions)
    .pipe(first()).subscribe(userResponse => {
      if (userResponse && userResponse) {
        localStorage.setItem('currentUser', JSON.stringify(userResponse));
        localStorage.setItem('currentToken', JSON.stringify(userResponse.accessToken));
        this.currentUserSubject.next(userResponse);
        this.currentTokenSubject.next(userResponse.accessToken);
        this.router.navigate(['/admin/cadastro/publicacao']);
      }
      return userResponse;
    });
  }

  Logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentToken');
    localStorage.removeItem('currentPeople');
    this.currentUserSubject.next(new User());
    this.currentTokenSubject.next('');
    window.location.reload();
  }

  ChangePassword(user: User): Subscription {
    return this.http.put<string>(`${environment.apiUrl}user/changePassword`, user, this.httpOptions).pipe(first()).subscribe(success => {
      this.success$.next(success);
    });
  }
}
