import { User } from '../model/user';

export class LoginValidation {
  static LoginValidation(user: User): string {
    let errorMessage = '';

    if (!user.login || user.login === '') {
      errorMessage += '- Informe o login \n';
    }

    if (!user.password || user.password === '') {
      errorMessage += '- Informe a senha';
    }

    return errorMessage;
  }
}
