<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef> Imagem </th>
      <td mat-cell *matCellDef="let element" (click)="view(element)">
        <img mat-card-avatar src="{{ element.base64Image }}">
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef> Tamanho </th>
      <td mat-cell *matCellDef="let element"> {{ element.size | number }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Ações </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <button mat-icon-button *ngFor="let button of editDeleteButtons"
          [color]="button.color"
          [disabled]="button.disabled"
          (click)="button.Click(element)"
          [type]="button.type">
          <mat-icon>{{ button.icon }}</mat-icon>
        </button>
    </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>
