<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
  <mat-form-field *ngIf="showId">
    <mat-label>Id</mat-label>
    <input matInput
    [(ngModel)]="personalData.id"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Titulo</mat-label>
    <mat-select [(ngModel)]="personalData.title">
      <mat-option [value]="1">Bacharelado</mat-option>
      <mat-option [value]="2">Mestra</mat-option>
      <mat-option [value]="3">Doutora</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Nome completo</mat-label>
    <input matInput
    [(ngModel)]="personalData.name"
    placeholder="Informe aqui seu nome" />
  </mat-form-field>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
  <mat-form-field>
    <mat-label>CPF</mat-label>
    <input matInput
    [(ngModel)]="personalData.cpf"
    [mask]="'000.000.000-00'"
    placeholder="Informe aqui seu CPF" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>CRP</mat-label>
    <input matInput
    [(ngModel)]="personalData.crp"
    [mask]="'00/000000'"
    placeholder="Informe aqui seu CRP" />
  </mat-form-field>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
  <mat-form-field class="example-full-width">
    <mat-label>Telefone</mat-label>
    <input matInput
      [(ngModel)]="personalData.telephone"
      [type]="'tel'"
      placeholder="(99) 99999-9999"
      [mask]="'(00) 00000-0000'"
    >
  </mat-form-field>
  <mat-form-field>
    <mat-label>E-mail</mat-label>
    <input matInput
    [(ngModel)]="personalData.email"
    [type]="'email'"
    placeholder="Ex.: vanessa@gmail.com.br" />
  </mat-form-field>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
  <mat-form-field class="example-full-width">
    <mat-label>Instagram</mat-label>
    <input matInput
      [(ngModel)]="personalData.instagram"
      [type]="'text'"
    >
  </mat-form-field>
  <mat-form-field>
    <mat-label>Facebook</mat-label>
    <input matInput
      [(ngModel)]="personalData.facebook"
      [type]="'text'"
    >
  </mat-form-field>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
  <mat-form-field>
    <mat-label>Texto profissional</mat-label>
    <textarea matInput
      [(ngModel)]="personalData.personalText"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdk
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="10">
    </textarea>
  </mat-form-field>
</div>
