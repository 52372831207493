import { Component, Input, OnInit } from '@angular/core';
import { Address } from 'src/app/model/address';
import { BaseService } from 'src/app/service/base/base.service';

@Component({
  selector: 'app-personal-data-adress',
  templateUrl: './personal-data-adress.component.html',
  styleUrls: ['./personal-data-adress.component.scss']
})
export class PersonalDataAdressComponent implements OnInit {

  @Input() address: Address;

  constructor() {
  }

  ngOnInit(): void {
  }

}
