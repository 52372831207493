import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageUpload } from 'src/app/model/image-upload';

@Component({
  selector: 'app-upload-photos-preview',
  templateUrl: './upload-photos-preview.component.html',
  styleUrls: ['./upload-photos-preview.component.scss']
})
export class UploadPhotosPreviewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UploadPhotosPreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ImageUpload) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getNameImage(): string {
    return this.data.name.split('.')[0];
  }
}
