<div *ngIf="!isSmall">
  <button *ngFor="let menu of menus" class="button-menu-toolbar" (click)="onClick(menu)">{{ menu.description }}</button>
</div>

<div class="menu-side" *ngIf="isSmall">
  <div *ngIf="isAdministrative === 'false'" class="contact">
    <app-contacts></app-contacts>
  </div>
  <div *ngFor="let menu of menus">
    <button class="button-menu-side" (click)="onClick(menu)">{{menu.description}}</button>
  </div>
</div>
