import { Component, Input, OnInit } from '@angular/core';
import { ImageUpload } from 'src/app/model/image-upload';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-upload-photos-form',
  templateUrl: './upload-photos-form.component.html',
  styleUrls: ['./upload-photos-form.component.scss']
})
export class UploadPhotosFormComponent implements OnInit {

  @Input() imagesUpload: Array<ImageUpload>;

  areaId: number;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() { }

  ngOnInit(): void {
  }

  remove(image: ImageUpload): void {
    const index = this.imagesUpload.indexOf(image);

    if (index >= 0) {
      this.imagesUpload.splice(index, 1);
    }
  }

  getArea(value: number): void {
    this.areaId = value;
    if (this.imagesUpload.length > 0) {
      this.imagesUpload.forEach(images => {
        images.area = value;
      });
    }
  }
}
