<div [ngClass]="isLoading ? 'loading' : ''" fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutAlign="center center" style="position: absolute;">
  <mat-spinner [color]="'accent'" [fxHide]="isLoading === false"></mat-spinner>
</div>

<div class="background" ngClass.lt-lg="background-phone" ngClass.gt-md="background-pc">
  <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutAlign="center center">
    <div fxLayoutAlign.lt-lg="center center" fxFlex="50">
      <div fxLayoutAlign="center center">
        <mat-card class="mat-elevation-z4" ngClass.lt-lg="mat-card-phone">
          <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>
              <img src="../../../../assets/logo_vanessa.png">
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <form (submit)="Login()">
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field class="input-login">
                  <mat-label>Login</mat-label>
                  <input
                    matInput
                    [(ngModel)]="user.login"
                    name="login"
                    [required]="true"
                    [readonly]="false"
                    [placeholder]="'Login'"
                    [type]="'text'">
                  <!-- <mat-error *ngIf="validation">{{validation.LoginMessage}}</mat-error> -->
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field class="input-login">
                  <mat-label>Senha</mat-label>
                  <input
                    matInput
                    [(ngModel)]="user.password"
                    name="password"
                    [required]="true"
                    [readonly]="false"
                    [placeholder]="'Senha'"
                    [type]="pass">
                    <!-- <mat-error *ngIf="validation">{{validation.PasswordMessage}}</mat-error> -->
                  <button class="padding-icon" type="button" mat-icon-button (click)="visibility(!visible)" matSuffix>
                    <mat-icon class="icon">
                      {{ icon }}
                    </mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <mat-card-actions>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <button mat-raised-button
                    class="margin-button"
                    [type]="'submit'"
                    [color]="'accent'"
                    [disabled]="isLoading">
                    Entrar
                  </button>
                </div>
              </mat-card-actions>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
