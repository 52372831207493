import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/@base/base.component';
import { DeleteDialogComponent } from 'src/app/components/@base/delete-dialog/delete-dialog.component';
import { Ibutton } from 'src/app/interface/ibutton';
import { Publication } from 'src/app/model/publication';
import { PublicationService } from 'src/app/service/publication.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { PublicationValidation } from 'src/app/validation/publication-validation';
import { TopicsComponent } from '../topics/topics.component';
import { PublicationEditDialogComponent } from './publication-edit-dialog/publication-edit-dialog.component';
import { PublicationFormComponent } from './publication-form/publication-form.component';
import { PublicationGridComponent } from './publication-grid/publication-grid.component';

@Component({
  selector: 'app-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.scss']
})
export class PublicationComponent extends BaseComponent implements OnInit {

  @ViewChild(PublicationFormComponent) publicationForm: PublicationFormComponent;
  @ViewChild(PublicationGridComponent) publicationGrid: PublicationGridComponent;

  addTopicButton: Ibutton;

  saveCleanButtons: Array<Ibutton> = new Array<Ibutton>();
  saveButton: Ibutton;
  cleanButton: Ibutton;

  editDeleteButtons: Array<Ibutton> = new Array<Ibutton>();
  editButton: Ibutton;
  deleteButton: Ibutton;

  publication: Publication = new Publication();
  publications: Array<Publication> = new Array<Publication>();

  isLoading = false;

  constructor(private dialog: MatDialog,
              private alert: AlertService,
              private publicationService: PublicationService) {
    super();

    this.subscriptions = new Array<Subscription>();

    this.subscriptions.push(
      this.publicationService.success$.subscribe(result => {
        this.publicationGrid.loadAllPublications();
        this.publication = new Publication();
        this.alert.success(result.message);
      })
    );
  }

  ngOnInit(): void {
    this.getButtons();
  }

  getButtons(): void {
    this.addTopicButton = {
      description: '',
      disabled: false,
      color: 'accent',
      type: 'button',
      icon: 'add',
      Click: () => {
        this.addTopicDialog();
      }
    };

    this.saveButton = {
      description: 'Salvar',
      disabled: false,
      color: 'accent',
      type: 'button',
      icon: 'save',
      Click: () => {
        this.savePublication();
      }
    };

    this.cleanButton = {
      description: 'Limpar',
      disabled: false,
      color: 'basic',
      type: 'button',
      icon: 'cleaning_services',
      Click: () => {
        this.cleanPublication();
      }
    };

    this.saveCleanButtons.push(this.saveButton);
    this.saveCleanButtons.push(this.cleanButton);

    this.editButton = {
      description: '',
      disabled: false,
      color: 'primary',
      type: 'button',
      icon: 'create',
      Click: (publication: Publication) => {
        this.editPublication(publication);
      }
    };

    this.deleteButton = {
      description: '',
      disabled: false,
      color: 'warn',
      type: 'button',
      icon: 'delete',
      Click: (publication: Publication) => {
        this.deletePublication(publication);
      }
    };

    this.editDeleteButtons.push(this.editButton);
    this.editDeleteButtons.push(this.deleteButton);
  }

  addTopicDialog(): void {
    const dialogRef = this.dialog.open(TopicsComponent, {
      width: 'auto',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.publicationForm.loadActiveTopics();
      this.publicationGrid.loadAllPublications();
    });
  }

  savePublication(): void {
    const errorMessage = PublicationValidation.PublicationValidation(this.publication);
    if (!errorMessage) {
      this.publicationService.Save(this.publication);
    } else {
      this.alert.warning('Atenção', errorMessage, { expanded: true });
    }
  }

  cleanPublication(): void {
    this.publication = new Publication();
  }

  editPublication(publication: Publication): void {
    const dialogRef = this.dialog.open(PublicationEditDialogComponent, {
      width: '500px',
      height: 'auto',
      data: publication
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const errorMessage = PublicationValidation.PublicationValidation(publication);
        if (!errorMessage) {
          this.publicationService.Update(publication);
        } else {
          this.alert.warning('Atenção', errorMessage, { expanded: true });
          this.publicationGrid.loadAllPublications();
        }
      }
    });
  }

  deletePublication(publication: Publication): void {
    if (publication) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: '50%',
        height: 'auto',
        data: {
          title: 'Excluir publicação',
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.publicationService.Delete(publication);
        }
      });
    }
  }
}
