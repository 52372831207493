<h1 mat-dialog-title>Publicações {{area === 1 ? 'adulto' : 'infantil'}}</h1>
<div mat-dialog-content>
  <div fxLayout="column">
    <div class="publications" fxLayoutAlign="center center">
      <div>
        <mat-form-field>
          <mat-label>Pesquisar</mat-label>
          <input matInput (keyup)="applyFilter(input.value)" placeholder="Ex. Drogas" #input>
        </mat-form-field>

        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="publication">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <mat-card (click)="viewPublicaation(row)" class="mat-elevation-z4">
                <mat-card-header>
                  <mat-card-title>
                    {{ row.topic.description }}
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content class="content" ngClass.lt-lg="card-content-phone" ngClass.gt-md="card-content-pc">
                  {{ row.publicationText }}
                </mat-card-content>
              </mat-card>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Nenhum registro encontrado com o  filtro: "{{input.value}}"</td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[2, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button (click)="onNoClick()">Fechar</button>
</div>
