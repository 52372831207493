import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AreaEnum } from 'src/app/model/Enum/AreaEnum';
import { Publication } from 'src/app/model/publication';
import { SeeMorePublicationsComponent } from '../see-more-publications/see-more-publications.component';
import { ShowPublicationDialogComponent } from '../show-publication-dialog/show-publication-dialog.component';

@Component({
  selector: 'app-show-publications',
  templateUrl: './show-publications.component.html',
  styleUrls: ['./show-publications.component.scss']
})
export class ShowPublicationsComponent implements OnInit {

  @Input() publications: Array<Publication> = new Array<Publication>();
  @Input() typePublication: AreaEnum;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  viewPublicaation(publication: Publication): void {
    const dialogRef = this.dialog.open(ShowPublicationDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: publication
    });
  }

  seeMorePublications(): void {
    const dialogRef = this.dialog.open(SeeMorePublicationsComponent, {
      width: 'auto',
      height: 'auto',
      data: this.typePublication
    });
  }
}

