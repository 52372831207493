import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/admin/login/login.component';
import { DefaultComponent } from './components/default/default.component';

const routes: Routes = [
  {
    path: 'admin',
    component: LoginComponent,
    data: {
      title: 'Login Administrativo'
    },
    children: [
      {
        path: 'admin',
        loadChildren: './components/admin/admin.module#AdminModule'
      }
    ]
  },
  {
    path: '',
    component: DefaultComponent,
    data: {
      title: 'Home',
      isAdministrative: 'false'
    },
    children: [
      {
        path: '',
        loadChildren: './components/client/client.module#ClientModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
