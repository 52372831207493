import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AreaEnum } from '../model/Enum/AreaEnum';
import { Publication } from '../model/publication';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class PublicationService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  GetAll(): Observable<Array<Publication>> {
    return this.http.get<Array<Publication>>(`${environment.apiUrl}publication`);
  }

  GetByArea(area: AreaEnum): Observable<Array<Publication>> {
    return this.http.get<Array<Publication>>(`${environment.apiUrl}publication/getbyarea?area=${area}`);
  }

  Save(publication: Publication): Subscription {
    return this.http.post<string>(`${environment.apiUrl}publication`, publication, this.httpOptions).pipe(first()).subscribe(success => {
      this.success$.next(success);
    });
  }

  Update(publication: Publication): Subscription {
    return this.http.put<string>(`${environment.apiUrl}publication`, publication, this.httpOptions).pipe(first()).subscribe(success => {
      this.success$.next(success);
    });
  }

  Delete(publication: Publication): Subscription {
    return this.http.delete<string>(`${environment.apiUrl}publication?Id=${publication.id}`).pipe(first()).subscribe(success => {
      this.success$.next(success);
    });
  }
}
