import { ImageUpload } from '../model/image-upload';

export class UploadPhotosValidation {
  static UploadPhotosValidation(imageUpload: ImageUpload): string {
    let errorMessage = '';

    if (!imageUpload) {
      errorMessage += '- Selecione pelo menos 1 imagem válida \n';
    } else {
      if (!imageUpload.area || imageUpload.area === 0) {
        errorMessage += '- Informe a área da imagem \n';
      }

      if (!imageUpload.base64Image) {
        errorMessage += '- Selecione pelo menos 1 imagem válida \n';
      }
    }

    return errorMessage;
  }
}
