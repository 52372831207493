import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorStatusEnum } from 'src/app/model/Enum/ErrorStatusEnum';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { UserService } from 'src/app/service/user.service';
import { LoadingService } from 'src/app/service/loading.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private userService: UserService,
                private alert: AlertService,
                private loadingService: LoadingService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
          const error = err.error || err.statusText;
          if (err.status === ErrorStatusEnum.Unauthorized) {
              this.alert.warning('Conexão expirada, faça o login novamente.');
              this.userService.Logout();
              location.reload();
          } else if (err.status === ErrorStatusEnum.NotFound) {
            this.alert.info(error.message);
          } else {
            this.alert.error(error.message);
          }

          this.loadingService.requestApi$.next(false);

          return throwError(error);
        }));
    }
}
