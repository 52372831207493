<mat-toolbar fxLayout="row" fxLayoutAlign="space-around center" ngClass.gt-md="main-toolbar" ngClass.lt-lg="main-toolbar-small">
  <div class="toolbar-botton" fxShow="true" fxHide.gt-md="true">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <img src="../../../../assets/Icons/instagram-icon.png" matTooltip="{{ personalData.instagram }}">
      <img src="../../../../assets/Icons/facebook-icon.png" matTooltip="{{ personalData.facebook }}">
      <img src="../../../../assets/Icons/whatsapp-icon.png" matTooltip="{{ personalData.telephone | mask: '(00) 00000-0000'}}">
      <img src="../../../../assets/Icons/email-icon.png" matTooltip="{{ personalData.email }}">
    </div>
  </div>
  <div fxShow="true" fxHide.lt-lg="true" class="toolbar-menu">
    <div class="toolbar-botton" fxLayout="row" fxLayoutAlign="space-around center">
      <div class="icon-toolbar-bottom" fxLayout="row" fxLayoutAlign="space-around center">
        <img src="../../../../assets/Icons/instagram-icon.png">
        <span>{{ personalData.instagram }}</span>
      </div>
      <div class="icon-toolbar-bottom" fxLayout="row" fxLayoutAlign="space-around center">
        <img src="../../../../assets/Icons/facebook-icon.png">
        <span>{{ personalData.facebook }}</span>
      </div>
      <div class="icon-toolbar-bottom" fxLayout="row" fxLayoutAlign="space-around center">
        <img src="../../../../assets/Icons/whatsapp-icon.png">
        <span>{{ personalData.telephone | mask: '(00) 00000-0000'}}</span>
      </div>
      <div class="icon-toolbar-bottom" fxLayout="row" fxLayoutAlign="space-around center">
        <img src="../../../../assets/Icons/email-icon.png">
        <span>{{ personalData.email }}</span>
      </div>
    </div>
  </div>
</mat-toolbar>
