import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingService extends BaseService {

  requestApi$ = new Subject<boolean>();

  constructor() {
    super();
  }

  showLoading(show: boolean): void {
    this.requestApi$.next(show);
  }

  getShowLoading(): Observable<boolean> {
    return this.requestApi$.asObservable();
  }
}
