<mat-toolbar layout="row" class="main-toolbar">
  <div class="toolbar-test-phone" fxShow="true" fxHide.gt-md="true">
    <button mat-icon-button
      (click)="drawer.toggle()">
      <mat-icon class="icon-phone">menu</mat-icon>
    </button>
    <img class="img-phone" src="../../../assets/logo_vanessa.png">
    <div class="toolbar-name-phone" *ngIf="personalData.name">
      <button class="button-menu-toolbar-phone" (click)="Home()">
        {{ getTitulacao() }} {{ personalData.name }}
        <br/>
        Psicologia
      </button>
    </div>
  </div>
  <div fxShow="true" fxHide.lt-lg="true" class="toolbar-menu">
    <img class="img-pc" src="../../../assets/logo_vanessa.png">
    <div class="toolbar-name-pc" *ngIf="personalData.name">
      <button class="button-menu-toolbar-pc" (click)="Home()">
        {{ getTitulacao() }} {{ personalData.name }}
        <br/>
        Psicologia
      </button>
    </div>
    <div *ngIf="isAdministrative === 'false'" class="contacts" fxLayoutAlign="center center">
      <app-contacts></app-contacts>
    </div>
    <app-menu [isAdministrative]="isAdministrative" [isSmall]="false"></app-menu>
    <div *ngIf="isAdministrative === 'true'" class="logout" fxLayoutAlign="center center">
      <app-logout></app-logout>
    </div>
  </div>
</mat-toolbar>
