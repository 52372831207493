<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="telephone" >
      <th mat-header-cell *matHeaderCellDef> Telefone </th>
      <td mat-cell *matCellDef="let element"> {{element.telephone | mask: '(00) 00000-0000'}} </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef> Respondido </th>
      <td mat-cell *matCellDef="let element">
        <mat-slide-toggle #slide
          [color]="contactedButton.color"
          [checked]="element.contacted"
          [disabled]="contactedButton.disabled"
          (click)="contactedButton.Click(element, !slide.checked)">
        </mat-slide-toggle>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[5, 10]"></mat-paginator>
</div>
