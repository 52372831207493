import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AreaEnum } from 'src/app/model/Enum/AreaEnum';
import { Publication } from 'src/app/model/publication';
import { PublicationService } from 'src/app/service/publication.service';
import { ShowPublicationDialogComponent } from '../show-publication-dialog/show-publication-dialog.component';

@Component({
  selector: 'app-see-more-publications',
  templateUrl: './see-more-publications.component.html',
  styleUrls: ['./see-more-publications.component.scss']
})
export class SeeMorePublicationsComponent implements OnInit, AfterViewInit {

  publications: Array<Publication> = new Array<Publication>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['publication'];
  dataSource = new MatTableDataSource<Publication>();

  constructor(public dialogRef: MatDialogRef<SeeMorePublicationsComponent>,
              @Inject(MAT_DIALOG_DATA) public area: AreaEnum,
              private publicationService: PublicationService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadPublication();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  loadPublication(): void {
    this.publicationService.GetByArea(this.area).subscribe(result => {
      this.publications = result;
      this.setDataSource();
    });
  }

  setDataSource(): void {
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.publications);
      this.dataSource.paginator = this.paginator;

      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.publicationText.toLowerCase() + data.topic.description.toLowerCase();
        return dataStr.indexOf(filter) !== -1;
      };
    });
  }

  remove(publication: any): void {
    const index: number = this.publications.indexOf(publication);

    if (index !== - 1) {
      this.publications.splice(index, 1);
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  viewPublicaation(publication: Publication): void {
    const dialogRef = this.dialog.open(ShowPublicationDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: publication
    });
  }

}
