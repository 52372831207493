import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LocationComponent } from './location/location.component';
import { AboutComponent } from './about/about.component';
import { AdultComponent } from './adult/adult.component';
import { KidsComponent } from './kids/kids.component';
import { SpaceComponent } from './space/space.component';

const routes: Routes = [
  {
    path: 'sobre',
    component: AboutComponent,
    data: {
      title: 'Sobre',
      isHomePage: false
    },
  },
  {
    path: 'kids',
    component: KidsComponent,
    data: {
      title: 'Kids',
      isHomePage: false
    }
  },
  {
    path: 'adulto',
    component: AdultComponent,
    data: {
      title: 'Adulto',
      isHomePage: false
    }
  },
  {
    path: 'localizacao',
    component: LocationComponent,
    data: {
      title: 'Localização',
      isHomePage: false
    }
  },
  {
    path: 'espaco',
    component: SpaceComponent,
    data: {
      title: 'Espaço',
      isHomePage: false
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
