import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/service/user.service';
import { finalize, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/service/loading.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private userService: UserService, private loadingService: LoadingService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const currentToken = this.userService.CurrentTokenValue;
      const noSpinner = request.headers.get('is-loading');

      if (!noSpinner) {
        this.loadingService.showLoading(true);
      } else if (noSpinner.toLowerCase() === 'true') {
        this.loadingService.showLoading(true);
      } else {
        setTimeout(() => {
          this.loadingService.requestApi$.next(false);
        });
      }
      if (currentToken) {
          request = request.clone({
              setHeaders: {
                  Authorization: `Bearer ${currentToken}`
              }
          });
      }

      return next.handle(request).pipe(
        tap(
          event => { },
          err => { }
        ),
        finalize(() => {
          setTimeout(() => {
            this.loadingService.requestApi$.next(false);
          });
        })
      );
    }
}
