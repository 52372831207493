import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PersonalData } from '../model/personal-data';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalDataService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  personalData$ = new Subject<PersonalData>();
  pData = new Subject<PersonalData>();

  setPersonalData(personalData: PersonalData): void {
    this.personalData$.next(personalData);
  }

  getPersonalData(): Observable<PersonalData> {
    let p = this.personalData$.asObservable();
    return p;
  }

  Get(): Observable<PersonalData> {
    return this.http.get<PersonalData>(`${environment.apiUrl}personal-data`);
  }

  Save(personalData: PersonalData): Subscription {
    return this.http.post<string>(`${environment.apiUrl}personal-data`, personalData, this.httpOptions).pipe(first()).subscribe(success => {
      this.success$.next(success);
    });
  }

  Update(personalData: PersonalData): Subscription {
    return this.http.put<string>(`${environment.apiUrl}personal-data`, personalData, this.httpOptions).pipe(first()).subscribe(success => {
      this.success$.next(success);
    });
  }
}
